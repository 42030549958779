
var admlog = false;
/* The current dom element containing a label being edited. */
var currentEditDom = null;
/* The currently selected text's id. */
var currentTextId = null;
/* This variable disables the application for the current page. */
var disableApp = false;
/* This object is the instance representation the current DOM Object being
   moved by the dragging method. */
var dragObject = null;
/* This variable signifies that the page has been loaded. */
var pageLoaded = false;
/* The offset of the mouse when the drag method is called. This is used as 
   part of the calculations to redraw the DOM Object being moved. */
var mouseOffset = null;
/* The coordinates of the mouse. */
var mouseCoordsXY = null;

/* The currently selected image in the upload image window (where there is
   an option to select a previously uploaded media). */
var selectedImageUpl = null;
/* The id of the item being swapped down in the access rights window. */
var swapDownId = null;

/* The bg color of the the previous selection so that it can be restored. */
var swapOldBgColor;
/* The id of the item being swapped up in the access rights window. */
var swapUpId = null;

var _componentsBulk = {};
var _mg2ManagerLang = "";






/* This method is used within the iframe and makes the call to the ajax action
   which saves the crop image based on the parameters found in the hidden
   inputs of the page -- which are set by code in the crop.js file. */
function cropImage() {
    var width = document.getElementById('cropWidth').value;
    var height = document.getElementById('cropHeight').value;
    var offsetX = document.getElementById('cropOffsetX').value;
    var offsetY = document.getElementById('cropOffsetY').value;
    var directory = document.getElementById('cropDirectory').value;
    var mediaId = document.getElementById('cropMediaId').value;
    ajax(actionPath + "cropImage?width="+width+"&height="+height+
         "&offsetX="+offsetX+"&offsetY="+offsetY+"&directory="+directory+
         "&mediaId="+mediaId, cropImageSaved);
}

/* Method called by the ajax callback of a successful submit of cropImage().
   This method simply just opens a confirmation window. */
function cropImageSaved() {
    openWindow('imageSaved', true);
}



/* Sets the parameters of the hidden inputs in the form and pops the delete
   image confirmation window.*/
function deleteImage(pageId, mediaId, localId, componentId, rule, width,height){
    openWindow('deleteImage', true);
    document.getElementById('imageFormDelPageId').value = pageId;
    document.getElementById('imageFormDelMediaId').value = mediaId;
    document.getElementById('imageFormDelLocalId').value = localId;
    document.getElementById('imageFormDelComponentId').value = componentId;
    document.getElementById('imageFormDelDirectory').value = 
        document.getElementById('cropDirectory').value;
    document.getElementById('imageFormDelRule').value = rule;
    document.getElementById('imageFormDelRuleWidth').value = width;
    document.getElementById('imageFormDelRuleHeight').value = height;
}

var imageManagerCurrentDelete = null;
function deleteImageManager(mediaId) {
    imageManagerCurrentDelete = mediaId;
    ajax(actionPath + "deleteImageManager?mediaId="+mediaId, 
    responseDeleteImageManager);
}

function responseDeleteImageManager(response) {
    if (response.indexOf("confirm") != -1) {
        openWindow('imageManagerDeleteConfirm', true);
    } else if (response.indexOf("used") != -1) {
        openWindow('imageManagerDeleteError', true);
    }
}

function confirmDeleteImageManager() {
    closeWindow('imageManagerDeleteConfirm');
    ajax(actionPath + "deleteImageManager?confirm=true&"+
    "mediaId="+imageManagerCurrentDelete, responseConfirmDeleteImageManager);
}

function responseConfirmDeleteImageManager(response) {
    document.getElementById('imageManagerDiv-'+imageManagerCurrentDelete)
    .style.display="none";
    imageManagerCurrentDelete = null;
}

/* Sets the parameters of the form to delete a text and pops the delete text
   confirmation window.*/
function deleteText() {
    document.getElementById('textFormDelLocalId').value = 
        document.getElementById('textFormLocalId'+currentTextId).value;
    document.getElementById('textFormDelComponentId').value = 
        document.getElementById('textFormComponentId'+currentTextId).value;
    document.getElementById('textFormDelTextId').value = 
        document.getElementById('textFormTextId'+currentTextId).value;
    openWindow('deleteText', true);
}


function displayPreview(id) {
    displayFullImage('/static/uploaded/Default/'+id+'-Croppable.jpg');
}



function lockPageWindow(id, ajaxWindow) {
    document.getElementById('restrictPageInput').value = "true";
    document.getElementById('restrictPageIdInput').value = id;
    if (ajaxWindow) {
        document.getElementById('restrictPageAjax').value = 'true';
        openWindow('lockPage',true);
    }
}

function unlockPageWindow(id, ajaxWindow) {
    document.getElementById('unrestrictPageInput').value = "false";
    document.getElementById('unrestrictPageIdInput').value = id;
    if (ajaxWindow) {
        document.getElementById('unrestrictPageAjax').value = 'true';
        openWindow('unlockPage',true);
    }
}

/* Returns the mouse offset coordinates (x,y) based on a given target 
   and event. */
function getMouseOffset(target, ev){
    ev = ev || window.event;
    
    var docPos    = getPosition(target);
    var mousePos  = mouseCoords(ev);
    return {x:mousePos.x - docPos.x, y:mousePos.y - docPos.y};
}

/* Returns the position (x:left, y:top) of the given element e. */
function getPosition(e){
    var left = 0;
    var top  = 0;
    
    while (e.offsetParent){
        left += e.offsetLeft;
        top  += e.offsetTop;
        e     = e.offsetParent;
    }
    
    left += e.offsetLeft;
    top  += e.offsetTop;
    
    return {x:left, y:top};
}


function imageManagerSize(select) {
    var div = document.getElementById('imageManagerContainer');
    var val = document.getElementById('imageManagerCurrentSize');
    var newVal = select.options[select.selectedIndex].value;
    div.innerHTML = (""+div.innerHTML).split("-"+val.innerHTML).join("-"+newVal);
    val.innerHTML = newVal;
}


var currentMediaDir = null;
function mediaMngrAddDirectoryOpen(dir) {
    currentMediaDir = dir;
    document.getElementById('addMediaDirectoryInput').value = "";
    openWindow('mediaListAddDirectory',true);
}

function mediaMngrAddDirectory() {
    ajax(actionPath + "addMediaDirectory?dir=" + currentMediaDir + document.getElementById('addMediaDirectoryInput').value, mediaMngrRefreshAdd);
}

function mediaMngrRefreshAdd() {
    var iframe = document.getElementById('mediaListingIframe');
    iframe.src = "mediaListingManager?directory="
        +currentMediaDir+"&noCache=" + Math.random();
    currentMediaDir = null;
    closeWindow('mediaListAddDirectory');
}

var parentMediaDir = null;
function mediaMngrDeleteDirectoryOpen(dir, parentDir) {
    currentMediaDir = dir;
    parentMediaDir = parentDir;
    openWindow('mediaListDeleteDirectory',true);
}

function mediaMngrDeleteDirectory() {
    ajax(actionPath + "deleteMediaDirectory?dir="+currentMediaDir, 
          mediaMngrRefreshDelete);
}

function mediaMngrRefreshDelete() {
    var iframe = document.getElementById('mediaListingIframe');
    iframe.src = iframe.src+"?dir="+parentMediaDir+"&noCache=" + Math.random();
    currentMediaDir = null;
    parentMediaDir = null;
    closeWindow('mediaListDeleteDirectory');
}

function mediaMngrUpload(dir) {
    document.getElementById('mediaManagerUploadFile').src = 'mediaManagerUploadFile?dir='+dir;
    openWindow('uploadFile', true);
}

function modifyCSS(localId, type, css) {
    document.getElementById('modCSS-div').style.display = "block";
    document.getElementById('modCSS-localId').value = localId;
    document.getElementById('modCSS-type').value = type;
    document.getElementById('modCSS-style').value = css;
}

/* Returns the coordinations (x,y) of the mouse based on a given event. */
function mouseCoords(ev){
    if(ev.pageX || ev.pageY){
        return {x:ev.pageX, y:ev.pageY};
    }
    try {
      return {
          x:ev.clientX + document.body.scrollLeft - document.body.clientLeft,
          y:ev.clientY + document.body.scrollTop  - document.body.clientTop
      };
    }catch (exception) { 
        
    }
}

/* Function that is binded to the document's mouse move event, in the case 
   that there is an element being dragged, then it will follow the mouse. */
function mouseMove(ev){
    ev = ev || window.event;
    var mousePos = mouseCoords(ev);
    mouseCoordsXY = mousePos;
    if(dragObject){      
        dragObject.style.position = 'absolute';
        dragObject.style.top      = mousePos.y - mouseOffset.y + "px";
        dragObject.style.left     = mousePos.x - mouseOffset.x + "px";
        return false;
    }
}

/* Event which indicates the mouse button has been released, we therefore
   "release" the object being dragged by setting the reference to it to null.*/
function mouseUp(){
    dragObject = null;
}

function mailinglistSubmit() {
    var email = document.getElementById('mailinglist-email').value;
    if (email == "" || email.indexOf('@') == -1 
        || email.indexOf('.') == -1 || email.length < 7) {
        //do nothing invalid email
    } else {
      var code = document.getElementById('mailinglist-code').value;
      ajax(actionPath + "newsletter?email="+email+"&code="+code, 
          onmailinglistSubmitResponse);
    }
}

function onmailinglistSubmitResponse() {
    addCookie("mailinglist", "true");
    document.getElementById('mailinglist-form').style.display="none";
    document.getElementById('mailinglist-thankyou').style.display="block";
}



/* Makes the ajax call to remove a user from the selected group. */
function removeUserGroup() {
    ajax(actionPath + "removeGroupUser?userId="+swapUpId+"&groupId="
        +selectedGroup, accessCompleteSwapUp);
}

function sendContactFormEmail(validate, count) {
    var subject = document.getElementById('contactForm-subject').value;
    var email = document.getElementById('contactForm-email').value;
    var targetEmail = (document.getElementById('targetEmail')?document.getElementById('targetEmail').value:"");
    var content = null;
    try {
        content = tinyMCE.get('contactForm-content').getContent();
    } catch (exception) { 
        content = document.getElementById('contactForm-content').value;
    }

    var appendField="";
    if(document.getElementById('fieldName1')!=null) {
        var total = 9;
        if (count) {
            total = count;
        }
        for (var i = 1; i < total; i++) {
            appendField+="&fieldName"+i+"="+
                encodeURIComponent(document.getElementById('fieldName'+i).value);
            if(document.getElementById('field'+i).type == "checkbox") {
                if (document.getElementById('field'+i).checked) {
                    appendField+="&field"+i+"=checked.";
                } else {
                    appendField+="&field"+i+"=not checked.";
                }
            } else {
              appendField+="&field"+i+"="+
                  encodeURIComponent(document.getElementById('field'+i).value);
            }
        }
        appendField+="&count="+total+"&targetEmail="+targetEmail;
    }
    var captcha = document.getElementById('contactForm-captcha');
    if (captcha) {
        appendField += '&captcha='+encodeURIComponent(captcha.value);
    }
    var form = document.getElementById('contactForm-Container');
    var inputs = form.getElementsByTagName("input");
    var validForm = true;    
    for (i in inputs) {
       
            if (inputs[i].type=="text" && inputs[i].value=="") {
                validForm = false;
                break;
            }
    }  
    
    if (!validForm && validate) {
        popError('error.message.emptyForm');
    } else {
        ajax(actionPath + "contactUs?subject="+encodeURIComponent(subject)
            +"&email="+encodeURIComponent(email)
            +"&content="+encodeURIComponent(content)
            +appendField, sendContactFormEmailConfirm);
    }   
    
}

function sendContactFormEmailProdentia() {
    var subject = document.getElementById('contactForm-subject').value;
    var email = document.getElementById('contactForm-email').value;
    var content = tinyMCE.get('contactForm-content').getContent();
    var appendField="";
    if(document.getElementById('fieldName1')!=null) {
        for (var i = 1; i < 9; i++) {
            appendField+="&fieldName"+i+"="
                +encodeURIComponent(document.getElementById('fieldName'+i).value);
            if(document.getElementById('field'+i).type == "checkbox") {
                if (document.getElementById('field'+i).checked) {
                    appendField+="&field"+i+"=checked.";
                } else {
                    appendField+="&field"+i+"=not checked.";
                }
            } else {
              appendField+="&field"+i+"="
                  +encodeURIComponent(document.getElementById('field'+i).value);
            }
        }
    }
    var form = document.getElementById('prodentiaContactForm');
    var inputs = form.getElementsByTagName("input");
    var validForm = true;
    for (i in inputs) {
        if (inputs[i].type=="text" && inputs[i].value=="") {
            validForm = false;
            break;
        }
    }
    if (!validForm) {
        popError('error.message.emptyForm');
    } else {
        ajax(actionPath + "contactUs?subject="+encodeURIComponent(subject)
            +"&email="+encodeURIComponent(email)
            +"&content="+encodeURIComponent(content)
            +appendField, sendContactFormEmailConfirm);
    }
}

function sendContactFormEmailConfirm() {
    document.getElementById('contactForm-Container').style.display = "none";
    document.getElementById('contactForm-sent').style.display = "block";
}

/* Simulates the selection of a previously uploaded media by surrounding it
   with a border of appropriate color (and de-selecting the previous one).
   This function also, of course, stores the the selection in a hidden input. */
function selectImageUpload(img, id) {
    if ((img.style.borderColor + "").indexOf("black") != -1) {
        if (selectedImageUpl) {
            selectedImageUpl.style.border = "2px solid black";
        }
        img.style.border = "2px solid red";
        selectedImageUpl = img;
        document.getElementById('mediaIdUploadForm').value = id;
        document.getElementById('uploadType2').checked = true;
    } else {
        img.style.border = "2px solid black";
        selectedImageUpl = null;
        document.getElementById('mediaIdUploadForm').value = "unselected";
    }
}

/* Selects the template and sets the hidden input to the proper value as well
   as updates the images on screen. */
function selectTemplate(name, source) {
    var input = document.getElementById('templateSelectInput_'+source);
    var oldImg = document.getElementById(
        'templateImg_' + source + '_' + input.value);
    var newImg = document.getElementById('templateImg_' + source + '_' + name);
    oldImg.src = oldImg.src.split("_sel").join("");
    newImg.src = newImg.src.split(".jpg").join("_sel.jpg");
    input.value = name;
}





/* Swaps the language in the url and loads the new url. This function requires
   that both the previous and new language be given as parameters or nothing
   will happen. */
function swapLang(oldlang, newlang) {
    addCookie("foxone.lang", newlang);
    var loc = ""+window.location;
    if (oldlang && newlang) {
        window.location = loc.split("/"+oldlang+"/").join("/"+newlang+"/");
    }
}



/* This method sets the url for the iframe of the upload image form based
   on the given parameters then opens the image uploading/editing window. */
function uploadImage(id, localId, componentId, 
    directory, pageId, rule, width, height) {
    var iframe = document.getElementById('uploadImageIframe');
    var source = "/crop?content&";
    if (id != '') {
        source += "mediaId="+id+"&";
    }
    source += "localId=" + localId;
    source += "&componentId=" + componentId;
    source += "&directory=" + directory;
    source += "&pageId=" + pageId;
    source += "&rule=" + rule;
    source += "&ruleWidth=" + width;
    source += "&ruleHeight=" + height;
    source += "&noCache=" + Math.random();
    iframe.src = source;
    openWindow('imageWindow', true);
}

/* This method checks to make sure that there are at least three characters
   in the search query.*/
function validateSearch(form, input) {
    if (document.getElementById(input).value.length < 3) {
        popErrorSite('search.tooshort');
    } else {
        document.getElementById(form).submit();
    }
}

function versionCheck() {
    var v = (""+navigator.appVersion).indexOf('MSIE 6');
    if (v > -1) {
        try {
            document.getElementById('topMenuBar').style.display="none";
        } catch (exception) { 
        }
        disableApp = true;
        popErrorSite('error.message.ie6');
    }
}

function submitComplexForm(id, cnt) {
    var form = document.getElementById(id);
    var errors = false;
    for (var i = 1; i < (cnt+1); i++) {
        try {
            var currField = document.getElementById('field'+i);
            var fieldName = 'fieldName'+i;
            if (document.getElementById(fieldName).value.indexOf("*") != -1) {
                if (currField.value == "") {
                    errors = true;
                    currField.className = 'formBorderWrong';
                } else {
                    currField.className = '';
                }
            } else {
                currField.className = '';
            }
            if (currField.value.indexOf("checkbox-") != -1 && !errors) {
                var localCnt = parseInt(currField.value.substring(9));
                currField.value = "";
                for (var j = 1; j < localCnt; j++) {
                    var f = document.getElementById('f'+i+'-'+j);
                    if (f.checked) {
                        if (currField.value != "") {
                            currField.value += ",";
                        }
                        currField.value += f.value;
                    }
                }
            }
        } catch (e) { }
    }

    if (errors) {
        popErrorSite('error.forms.missing.fields');
    } else {
        form.submit();
    }
}

function toggleBannerForm(sel) {
    document.getElementById('bannerForm-upload').style.display='none';
    document.getElementById('bannerForm-imageurl').style.display='none';
    document.getElementById('bannerForm-flashurl').style.display='none';
    document.getElementById('bannerForm-html').style.display='none';
    document.getElementById('bannerForm-design').style.display='none';
    document.getElementById('bannerForm-'
        +sel.options[sel.selectedIndex].value).style.display='block';
}

function addBannerForm(zoneId) {
    document.getElementById('bannerAddModForm').reset();
    document.getElementById('bannerForm').style.display='block';
    document.getElementById('bannerIdMod').value='';
    document.getElementById('zoneIdMod').value=zoneId;
}

function cancelBannerForm() {
    document.getElementById('bannerAddModForm').reset();
    document.getElementById('bannerForm-upload').style.display='block';
    document.getElementById('bannerForm-imageurl').style.display='none';
    document.getElementById('bannerForm-flashurl').style.display='none';
    document.getElementById('bannerForm-html').style.display='none';
    document.getElementById('bannerForm-design').style.display='none';
    document.getElementById('bannerForm').style.display='none';
}

function deleteBannerConfirm(id) {
    document.getElementById('deleteBannerBannerId').value = id;
    //openWindow('deleteBanner', true);
    document.getElementById('formDeleteBanner').submit();
}

var subscribeIdsTemp = new Array();
function updateSubscribe(id, checkbox) {
    if (checkbox.checked) {
        subscribeIdsTemp[id] = true;
    } else {
        subscribeIdsTemp[id] = false;
    }
}

function subscribeToBlog() {
    var email = document.getElementById('subscribeEmail');
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email.value)) {
        popErrorSite('error.forms.invalid.email');
    } else {
        var firstName = document.getElementById('subscribeFirstNameEmail').value;
        var lastName = document.getElementById('subscribeLastNameEmail').value;
        var categoryId = '-1';
        try {
            var sel = document.getElementById('subscribeCategoryId');
            categoryId = sel.options[sel.selectedIndex].value;
        } catch (e) {
        }
        ajax(actionPath + "subscribeBlog?email="+encodeURIComponent(email.value)
            +"&firstName="+encodeURIComponent(firstName)+"&lastName="+encodeURIComponent(lastName)
            +"&categoryId="+categoryId, subscribeToBlogComplete);
    }
}

function subscribeToBlogComplete() {
    addCookie('mailinglist','true');
    document.getElementById('newsletterFormFields').style.display="none";
    document.getElementById('newsletterConfirm').style.display="block";
}

function refreshPage(name) {
    window.location=name+'?ok'+Math.random();
}

//ajax call to fetch the percentage of completion for sending a newsletter.
function rfBlogPercentage() {
    ajax('/action/getBlogSentPerc', updateBlogPercentage, true);
}
function updateBlogPercentage(response) {
    var div = document.getElementById('blogPercBar');
    div.style.width = response + 'px';
    if ((""+response).indexOf('100') == -1) {
        window.setTimeout(rfBlogPercentage, 2500);
    }
    div.title = response + "%";
}
function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}



function sortTreeElements(id){
    swapTreeElement(id, true, '');
}


function migrateLabels() {
    closeAllMenu();
    openWindow('manageMG2Labels', true);
}
function completeMigrateLabels() {
    window.location = '/action/reset';
}
function appendComponentsBulk(componentId, directory) {
    if (!_componentsBulk[componentId]) {
        _componentsBulk[componentId] = [];
    }
    _componentsBulk[componentId].push(directory);
}
function bulkImageWindow(count, componentId) {
    document.getElementById('bulkUploadImgCount').innerHTML = count;
    document.getElementById('bulkImgCount-input').value = count;
    document.getElementById('bulkImg-componentId').value = componentId;
    try {
        document.getElementById('bulkImg-directory').value = _componentsBulk[componentId].join(",");
        openWindow('mgBulkImager');
    } catch (exception) {
        location.reload(true);
    }
}
function bulkUploadGo() {
    ajaxBlackout(true,false);
    submitForm('bulkImgUploadForm');
}
$(document).ready(function() {
    if (typeof $.colorbox === 'function') {
        var xy = getViewPaneSize();
        if (xy[0] < 800) {
            //Disable color-box, resolution is too small.
        } else {
            try {
                if (_colorBoxSlideShow != null) {
                    $(".mg2ManagerGallery").colorbox({
                        rel:'mg2ManagerGallery', 
                        transition:"fade",
                        current:"{current} / {total}",
                        slideshow:true,
                        slideshowSpeed:_colorBoxSlideShow,
                        slideshowStop:"pause slideshow",
                        slideshowStart:"start slideshow"
                    });            
                } else {
                    $(".mg2ManagerGallery").colorbox({
                        rel:'mg2ManagerGallery', 
                        transition:"fade",
                        current:"{current} / {total}"
                    });
                }
            } catch (e1) { 
                try {
                    $(".mg2ManagerGallery").colorbox({
                        rel:'mg2ManagerGallery', 
                        transition:"fade",
                        current:"{current} / {total}"
                    });
                } catch (e2) { 
                    try {
                        if (_colorBoxDebug) {
                            alert(exception);
                        }
                    } catch (e3) { 
                    }
                }
            }    
        }
    }
});

function chownUser() {
    ajax('/action/chownUser?username='+document.getElementById('chown-user-name').value, chownUserComplete);
}
function chownUserComplete() {
    closeWindow('chownUserMaster');
    alert('Chown operation completed!');
}

function createAdminUsers() {
    closeAllMenu();
    ajax('/action/createAdmins',createAdminsComplete);
}
function createAdminsComplete() {
    alert('The admin users have been created!');
}
function charCountWindow(id, input) {
    var cntDiv = document.getElementById(id+'Counter');
    if (cntDiv) {
        cntDiv.innerHTML = input.value.length;
    }
}
function pageBlurUrlFormat(lang, input, inputToFillName) {
    var currInputToFill = document.getElementById(inputToFillName+lang);
    var value = input.value;
    
    if (currInputToFill !== null) {
        if (currInputToFill.value.length === 0) {
            value = value.trim();
            value = value.toLowerCase();
            value = value.replace(new RegExp("\\!", "g"), "");
            value = value.replace(new RegExp("\\?", "g"), "");
            value = value.replace(new RegExp("'", "g"), "");
            value = value.replace(new RegExp(" ", "g"), "-");
            value = value.replace(new RegExp("à", "g"), "a");
            value = value.replace(new RegExp("â", "g"), "a");
            value = value.replace(new RegExp("ä", "g"), "a");
            value = value.replace(new RegExp("ä", "g"), "a");
            value = value.replace(new RegExp("è", "g"), "e");
            value = value.replace(new RegExp("ê", "g"), "e");
            value = value.replace(new RegExp("é", "g"), "e");
            value = value.replace(new RegExp("ë", "g"), "e");
            value = value.replace(new RegExp("ç", "g"), "c");
            value = value.replace(new RegExp("î", "g"), "i");
            value = value.replace(new RegExp("ï", "g"), "i");
            value = value.replace(new RegExp("ò", "g"), "o");
            value = value.replace(new RegExp("ô", "g"), "o");
            value = value.replace(new RegExp("û", "g"), "u");
            value = value.replace(new RegExp("œ", "g"), "oe");
            value = value.replace(new RegExp("æ", "g"), "ae");
            
            currInputToFill.value = encodeURIComponent(value);
        }
    }
}


var currentMMHolder = null;
var currentYYHolder = null;
var eventsAdminGlobal = false;
function eventsFetchMonth(mm,yy,currentMM,currentYY,eventsAdmin) {
    eventsAdminGlobal = (eventsAdmin?true:false);
    var prefix = (eventsAdminGlobal) ? 'admin-events-mm-' : 'events-mm-';
    var newMonth = document.getElementById(prefix+''+mm+"-"+yy);
    if (newMonth) {
        document.getElementById(
            prefix+''+currentMM+"-"+currentYY).style.display='none';
        newMonth.style.display='block';
    } else {
        currentMMHolder = currentMM;
        currentYYHolder = currentYY;
        ajax(actionPath + "fetchMonth?mm="+mm+"&yy="+yy
            +(eventsAdminGlobal?"&event=true":""),displayNextMonth,true);
    }
}

var firstSelected = false;
var firstSelectedDate = new Array();
var secondSelected = false;
var secondSelectedDate = new Array();

var currStartDD = null;
var currStartMM = null;
var currStartYY = null;
var currEndDD = null;
var currEndMM = null;
var currEndYY = null;

function displayNextMonth(calendarData) {
    var prefix = 
        (eventsAdminGlobal) ? 'admin-events-mm-' : 'events-mm-';
    var containerId = 
        (eventsAdminGlobal)? 'adminEventsContainer' : 'eventsContainer';
    document.getElementById(prefix+''+currentMMHolder+"-"+currentYYHolder)
        .style.display='none';
    document.getElementById(containerId).innerHTML += calendarData.split("Z$").join("&");
    if (firstSelected) {
        try {
            var tdf = document.getElementById(
            "td-"+firstSelectedDate[2]+"-"
                +firstSelectedDate[1]+"-"+firstSelectedDate[0]);
            tdf.className='eventsTdAdminSelected';
            firstSelected = false;
        } catch (exception) {
        }
    }
    if (secondSelected) {
        try {
            var tds = document.getElementById(
            "td-"+secondSelectedDate[2]+"-"
                +secondSelectedDate[1]+"-"+secondSelectedDate[0]);
            tds.className='eventsTdAdminSelected';
            secondSelected = false;
        } catch (exception) {
        }
    }
    currentMMHolder = null;
    currentYYHolder = null;
}

function eventsSelectDate(dd, mm, yy) {
    var td = document.getElementById("td-"+yy+"-"+mm+"-"+dd);
    var tds = document.getElementById(
        "td-"+currStartYY+"-"+currStartMM+"-"+currStartDD);
    var tde = document.getElementById(
        "td-"+currEndYY+"-"+currEndMM+"-"+currEndDD);
    if (currStartDD == currEndDD &&
        currStartMM == currEndMM && currStartYY == currEndYY) {
        currEndDD = null;
        currEndMM = null;
        currEndYY = null;
    }
    if (currStartDD == null) {
        currStartDD = dd;
        currStartMM = mm;
        currStartYY = yy;
        currEndDD = dd;
        currEndMM = mm;
        currEndYY = yy;
        try{td.className='eventsTdAdminSelected';}catch(e){}
    } else if (dd == currStartDD && mm == currStartMM && yy == currStartYY) {
        if (currEndDD != null) {
            currStartDD = currEndDD;
            currStartMM = currEndMM;
            currStartYY = currEndYY;
            try{tds.className='eventsCalTdAdmin';}catch(e){}
        } else {
            currStartDD = currEndDD;
            currStartMM = currEndMM;
            currStartYY = currEndYY;
            try{td.className='eventsCalTdAdmin';}catch(e){}
        }
    } else if (currEndDD != null &&
               dd == currEndDD && mm == currEndMM && yy == currEndYY) {
        currEndDD = currStartDD;
        currEndMM = currStartMM;
        currEndYY = currStartYY;
        try{td.className='eventsCalTdAdmin';}catch(e){}
    } else {
        if ( yy < currStartYY ||
            (yy == currStartYY && mm < currStartMM) ||
            (yy == currStartYY && mm == currStartMM && dd < currStartDD)) {
            if (currEndDD != null) {
                currStartDD = dd;
                currStartMM = mm;
                currStartYY = yy;
                try{tds.className='eventsCalTdAdmin';}catch(e){}
                try{td.className='eventsTdAdminSelected';}catch(e){}
            } else {
                currEndDD = currStartDD;
                currEndMM = currStartMM;
                currEndYY = currStartYY;
                currStartDD = dd;
                currStartMM = mm;
                currStartYY = yy;
                try{td.className='eventsTdAdminSelected';}catch(e){}
            }
        } else if (currEndDD != null && (yy > currStartYY ||
            (yy == currStartYY && mm > currStartMM) ||
            (yy == currStartYY && mm == currStartMM && dd > currStartDD))) {
                currEndDD = dd;
                currEndMM = mm;
                currEndYY = yy;
                try{tde.className='eventsCalTdAdmin';}catch(e){}
                try{td.className='eventsTdAdminSelected';}catch(e){}
        } else {
            currEndDD = dd;
            currEndMM = mm;
            currEndYY = yy;
            try{td.className='eventsTdAdminSelected';}catch(e){}
        }
    }
    if (currStartDD != null) {
        document.getElementById('firstDateEventsManager')
            .innerHTML=
               ((currEndDD == null)?_EVENTS_PREFIX[0]:_EVENTS_PREFIX[1])+" "+
                currStartDD+" "+_MONTHS[currStartMM]+", "+currStartYY;
        document.getElementById('firstDateEventsContainer')
        .style.display="block";
    } else {
        document.getElementById('firstDateEventsManager').innerHTML="";
        document.getElementById('firstDateEventsContainer')
        .style.display="none";
    }
    if (currEndDD != null) {
        document.getElementById('secondDateEventsManager')
            .innerHTML=_EVENTS_PREFIX[2]+" "+currEndDD
            +" "+_MONTHS[currEndMM]+", "+currEndYY;
        document.getElementById('secondDateEventsContainer')
        .style.display="block";
    } else {
        document.getElementById('secondDateEventsManager').innerHTML="";
        document.getElementById('secondDateEventsContainer')
        .style.display="none";
    }
    document.getElementById('eventForm-startdd').value =
        (currStartDD == null)?"":currStartDD;
    document.getElementById('eventForm-startmm').value =
        (currStartMM == null)?"":currStartMM;
    document.getElementById('eventForm-startyy').value =
        (currStartYY == null)?"":currStartYY;
    document.getElementById('eventForm-enddd').value =
        (currEndDD == null)?"":currEndDD;
    document.getElementById('eventForm-endmm').value =
        (currEndMM == null)?"":currEndMM;
    document.getElementById('eventForm-endyy').value =
        (currEndYY == null)?"":currEndYY;
}

function eventsSubmitForm() {
    var startDD = parseInt(document.getElementById('eventForm-startdd').value);
    var startmm = parseInt(document.getElementById('eventForm-startmm').value);
    var startYY = parseInt(document.getElementById('eventForm-startyy').value);
    var startHH = parseInt(document.getElementById('eventForm-starthh').value);
    var startMM = parseInt(document.getElementById('eventForm-startMM').value);
    var endDD = parseInt(document.getElementById('eventForm-enddd').value);
    var endmm = parseInt(document.getElementById('eventForm-endmm').value);
    var endYY = parseInt(document.getElementById('eventForm-endyy').value);
    var endHH = parseInt(document.getElementById('eventForm-endhh').value);
    var endMM = parseInt(document.getElementById('eventForm-endMM').value);
    if ((startDD == endDD && startmm == endmm && startYY == endYY) &&
        ((startHH == endHH && startMM > endMM) || startHH > endHH)) {
          popError('errorMessageAjax');
          document.getElementById('errorMessageAjax').innerHTML =
            _EVENTS_ERRORS[2];
    } else if (document.getElementById('eventForm-startdd').value == "") {
          popError('errorMessageAjax');
          document.getElementById('errorMessageAjax').innerHTML =
            _EVENTS_ERRORS[0];
    } else if (document.getElementById('eventForm-title').value == ""
        || document.getElementById('eventForm-location').value == "") {
          popError('errorMessageAjax');
          document.getElementById('errorMessageAjax').innerHTML =
            _EVENTS_ERRORS[1];
    } else {
        document.getElementById('addEventForm').submit();
    }
}

function eventsWindowManagerOpen() {
    try {
        document.getElementById('event-price-manager-link').style.display='none';
    } catch (exception) { 
    }
    openWindow('eventsManager', false);
}
function eventsModifyEvent(id) {
    currentEventPriceEventId = id;
    openWindow('eventsManager');
    document.getElementById('eventForm-eventId').value=id;
    var startDD = document.getElementById('mod-eventForm-startdd').innerHTML;
    var startmm = document.getElementById('mod-eventForm-startmm').innerHTML;
    var startYY = document.getElementById('mod-eventForm-startyy').innerHTML;
    var startHH = document.getElementById('mod-eventForm-starthh').innerHTML;
    var startMM = document.getElementById('mod-eventForm-startMM').innerHTML;
    var endDD = document.getElementById('mod-eventForm-enddd').innerHTML;
    var endmm = document.getElementById('mod-eventForm-endmm').innerHTML;
    var endYY = document.getElementById('mod-eventForm-endyy').innerHTML;
    var endHH = document.getElementById('mod-eventForm-endhh').innerHTML;
    var endMM = document.getElementById('mod-eventForm-endMM').innerHTML;
    document.getElementById('eventForm-startdd').value=startDD;
    document.getElementById('eventForm-startmm').value=startmm;
    document.getElementById('eventForm-startyy').value=startYY;
    document.getElementById('eventForm-starthh').selectedIndex=startHH;
    var indexStartMM = parseInt(startMM)/5;
    document.getElementById('eventForm-startMM').selectedIndex=indexStartMM;
    eventsSelectDate(startDD, startmm, startYY);
    firstSelected = true;
    firstSelectedDate[0] = startDD;
    firstSelectedDate[1] = startmm;
    firstSelectedDate[2] = startYY;
    if (startDD != endDD || startmm != endmm || startYY != endYY) {
        document.getElementById('eventForm-enddd').value=endDD;
        document.getElementById('eventForm-endmm').value=endmm;
        document.getElementById('eventForm-endyy').value=endYY;
        document.getElementById('eventForm-endhh').selectedIndex=endHH;
        var indexEndMM = parseInt(endMM)/5;
        document.getElementById('eventForm-endMM').selectedIndex=indexEndMM;
        eventsSelectDate(endDD, endmm, endYY);
        secondSelected = true;
        secondSelectedDate[0] = endDD;
        secondSelectedDate[1] = endmm;
        secondSelectedDate[2] = endYY;
    } else if (startHH != endHH || startMM != endMM) {
        document.getElementById('eventForm-endhh').selectedIndex=endHH;
        var indexEndMM = parseInt(endMM)/5;
        document.getElementById('eventForm-endMM').selectedIndex=indexEndMM;        
    }
    try {
        var closingdd = document.getElementById('mod-eventForm-closingdd').innerHTML;
        var closingMM = document.getElementById('mod-eventForm-closingMM').innerHTML;
        var closingyy = document.getElementById('mod-eventForm-closingyy').innerHTML;
        document.getElementById('dayClosing').value=closingdd;
        document.getElementById('monthClosing').value=(parseInt(closingMM)-1);
        document.getElementById('yearClosing').value=closingyy;
        if (closingdd != '') {
            document.getElementById('eventForm-closingDate').value = closingdd + '/' + closingMM + '/' + closingyy;
        }
    } catch (exception) { 
        //No closing date, ignore.
    }
    document.getElementById('eventForm-title').value = document.getElementById('mod-eventForm-title').innerHTML;
    document.getElementById('eventForm-host').value = document.getElementById('mod-eventForm-host').innerHTML;
    document.getElementById('eventForm-location').value = document.getElementById('mod-eventForm-location').innerHTML;
    try{
        document.getElementById('eventForm-priceNormal').value = document.getElementById('mod-eventForm-priceNormal').innerHTML;
    } catch (exception) {
        //No price field.
    }
    try{
        document.getElementById('eventForm-locationLink').value = document.getElementById('mod-eventForm-locationLink').innerHTML;
        document.getElementById('eventForm-presentationLink').value = document.getElementById('mod-eventForm-presentationLink').innerHTML;
        document.getElementById('eventForm-photoGalleryLink').value = document.getElementById('mod-eventForm-photoGalleryLink').innerHTML;
        document.getElementById('eventForm-videoLink').value = document.getElementById('mod-eventForm-videoLink').innerHTML;
        document.getElementById('eventForm-credits').value = document.getElementById('mod-eventForm-credits').innerHTML;
        document.getElementById('eventForm-maxAttendees').value = document.getElementById('mod-eventForm-maxAttendees').innerHTML;
        document.getElementById('eventForm-partners').value = document.getElementById('mod-eventForm-partners').innerHTML;
        document.getElementById('eventForm-foodChoices').checked = 
            (document.getElementById('mod-eventForm-foodChoices').innerHTML == "true");
        document.getElementById('eventForm-vegetarien').checked = 
            (document.getElementById('mod-eventForm-vegetarien').innerHTML == "true");
        document.getElementById('eventForm-ccOnly').checked = 
            (document.getElementById('mod-eventForm-ccOnly').innerHTML == "true");
        document.getElementById('eventForm-preauth').checked = 
            (document.getElementById('mod-eventForm-preauth').innerHTML == "true");
        document.getElementById('eventForm-eventType').selectedIndex = 
            parseInt(document.getElementById('mod-eventForm-eventType').innerHTML);
        var creditType = document.getElementById('mod-eventForm-creditType').innerHTML;
        if (creditType == "CE") {
            document.getElementById('eventForm-creditType').selectedIndex = 1;
        } else if (creditType == "SER") {
            document.getElementById('eventForm-creditType').selectedIndex = 2;
        }
    } catch (exception) {
        //No extra fields.
    }
    try {
        if (_eventsForwardAfterSave) {
            document.getElementById('eventForm-pageSuccess').value = _eventsForwardAfterSave + '' + id;
        }
    } catch (exception) { 
        
    }

    try {
        var catId = document.getElementById('mod-eventForm-categoryId').innerHTML;
        document.getElementById('eventForm-categoryId').value = catId;
    } catch (exception) { 
        //No categories available.
    }
    try {
        document.getElementById('event-price-manager-link').style.display='block';
    } catch (exception) { 
    }
    tinyMCE.get('eventForm-description').setContent(
        document.getElementById('mod-eventForm-description').innerHTML);
    eventsFetchMonth(startmm,startYY,_EVENTS_CURRENT_MM,_EVENTS_CURRENT_YY,true);
}

function eventsDeleteEvent(id) {
    openWindow('deleteEvent',true);
    document.getElementById('eventId-deleteEvent').value=id;
}
